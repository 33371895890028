<template>
  <div class="proposal_history">
    <ul ref="his_Ul" class="his_ul" v-if="operatorLog.length">
      <li class="his_single" :class="'his_single' + item.id"
          v-for="(item, index) in operatorLog"
          :key="item.id">
        <div class="his_right">
          <div class="flex-x-between">
            <h5 class="his_r_h5">
              <span class="his_h_icon">{{operatorLog.length - index}}</span>
              <span>{{item.noteName}}</span>
              <span class="create_time">{{item.generateDatetime}}</span>
            </h5>
            <div v-if="+item.id !== 0" @click="openNewThreeD(item)" class="c-orange fzBold curp">
              {{+item.id === +$route.query.cureNoteId ?
              $t('casesDetail.casesChildren.textPlanChildren.dqfa') : $t('cases.createImage.ck')}}
            </div>
          </div>

          <div class="his_r_box">
            <template v-if="item.specialInstructions">
              <p class="doctor_opinion">{{$t('casesDetail.casesChildren.operationChildren.ysjy')}}：</p>
              <p class="special_explain">{{item.specialInstructions }}</p>
            </template>
            <p class="suggest">{{$t('casesDetail.casesChildren.operationChildren.ylkjjy')}}:</p>
            <p class="honorific_doctor">{{item.note}}</p>
            <template v-if="item.cureSuggestList && item.cureSuggestList.length">
              <div v-for="(it, idx) in item.cureSuggestList" :key="idx" class="his_r__yj">
                <div class="yj_left">
                  <p class="mb10">
                    {{dictionariesObj[it.operatorNode]}}
                    {{it.createDatetime}}
                  </p>
                  <span>{{it.suggest}}</span>
                </div>
              </div>
            </template>
          </div>
        </div>
      </li>
    </ul>
    <div class="no_his" v-else>
      <div class="no_his_pic" :class="{
        'admin': 'blue_pic',
        'clinic': 'blue_pic',
        'irdt': 'purple_pic',
      }[queryPort] || 'yellow_pic'"></div>
      <p class="no_his_txt">{{$t('casesDetail.casesChildren.operationChildren.zswjy')}}～</p>
    </div>
  </div>
</template>

<script>
  import {cureSuggestList} from 'common/api/cases';
  import {splitText} from 'common/js/util';
  import {detailCurePlan} from 'common/api/cases';
  import {get, each} from 'lodash';
  import {getDictList} from 'common/api/public';

  export default {
    data() {
      return {
        operatorLog: [],
        dictionariesObj: {}
      }
    },
    props: {
      cureSummary: {
        type: Object,
        default: () => ({})
      },
      schemeType:{
        type: String,
      }
    },
    computed: {
      queryPort(){
        return this.$route.query.port;
      }
    },
    methods:{
      get,
      openNewThreeD(item){
        if(+item.cureNoteId === +this.$route.query.cureNoteId) return;
        let obj = this.$route.query;
        let str = '';
        for (let i in obj){
          str += `${i}=${i === 'cureNoteId' ? item.id : obj[i]}&`
        }
        window.open('/cases/view3d?' + str, '_blank');
      },
      getList() {
        let {caseId, token} = this.$route.query;
        cureSuggestList({caseId, token}).then(logList => {
          this.operatorLog = logList;
        }).then(() => {
          let wrap = document.querySelector('.mo_con');
          let number = 0;
          let list = [];
          each(this.operatorLog, (item, index) => {
            if(+item.id === +this.$route.query.cureNoteId){
              list = this.operatorLog.slice(0,index);
            }
          });
          each(list, item => {
            number += document.querySelector(`.his_single${item.id}`).offsetHeight
          });
          wrap.scrollTop = number;
        });
      },
      getSuggestDictionaries() {
        getDictList('cure_suggest.node').then((data) => {
          each(data, item => {
            this.dictionariesObj[item.key] = item.value;
          });
        })
      }
    },
    created() {
      this.getList();
      this.getSuggestDictionaries();
    }
  }
</script>

<style scoped lang="scss">
  .proposal_history{
    width: 100%;
    color: $main_theme_color_333;
    font-size: 0.16rem;
    background-color: #fff;
    padding: 0.4rem;
    .his_ul{
      .his_single{
        .his_right{
          width: 100%;
          .his_r_h5{
            display: flex;
            align-items: center;
            font-family:Microsoft YaHei;
            position: relative;
            .his_h_icon{
              display: inline-block;
              width: 0.2rem;
              height: 0.2rem;
              line-height: 0.2rem;
              text-align: center;
              border-radius: 100%;
              background-color: $main_theme_color;
              color: #fff;
              font-size: 0.12rem;
              transform: translateX(-50%);
            }
            .create_time{
              color: #858585;
              font-size: 0.14rem;
              margin-left: 0.2rem;
            }
            .present_scheme{
              color: $main_theme_color;
              position: absolute;
              top: 0;
              right: 0;
            }
          }
          .his_r_box{
            padding: 0.29rem 0 0.3rem 0.2rem;
            border-left: 0.01rem solid #DADADA;
            .doctor_opinion{
              font-size: 16px;
              font-family: Source Han Sans CN;
              font-weight: 500;
              color: #303133;
              line-height: 0.23rem;
              margin-bottom: 0.14rem;
            }
            .special_explain{
              font-size: 14px;
              font-family: Source Han Sans CN;
              font-weight: 500;
              color: #303133;
              line-height: 0.23rem;
              margin-bottom: 0.14rem;
            }
            .suggest{
              font-size: 0.16rem;
              font-family: Source Han Sans CN;
              font-weight: 500;
              line-height: 0.23rem;
              color: #303133;
              margin-bottom: 0.13rem;
            }
            .honorific_doctor{
              font-size: 0.14rem;
              line-height: 0.23rem;
              color: #303133;
              margin-bottom: 0.28rem;
            }
            .his_r_p{
              color: #999999;
              font-size: 0.16rem;
              margin-bottom: 0.14rem;
            }
            .his_con{
              margin-bottom: 0.19rem;
              position: relative;
              .his_c_txt{
                overflow: hidden;
                text-overflow: ellipsis;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                line-height: 0.3rem;
                color: #999999;
                font-size: 0.16rem;
              }
              .his_c_sp{
                position: absolute;
                right: 0;
                bottom: 0;
                transform: translate(110%, -50%);
                color: $main_theme_color;
                cursor: pointer;
              }
            }
            .his_r__yj{
              width: 4rem;
              background-color: #eeeeee;
              font-size: 0.14rem;
              padding: 0.22rem 0.5rem 0.23rem 0.29rem;
              display: flex;
            }
            .yj_left{
              min-width: 0.9rem;
              margin-right: 0.12rem;
              padding-top: 0.08rem;
              line-height: 0.2rem;
            }
            .yj_right{
              flex: 1;
              .yj_single{
                width: 3rem;
                word-wrap: break-word; word-break: normal;
                line-height: 0.3rem;
                color: #999999;
                font-size: 0.16rem;
                &:last-child{
                  margin-bottom: 0;
                }
              }
            }
          }
        }
        &:last-child .his_r_box{
          border: none;
        }
      }
    }
    .no_his{
      padding: 0.87rem 0 1.04rem;
      display: flex;
      align-items: center;
      flex-direction: column;
      color: $main_theme_color_333;
      .no_his_pic{
        width: 2.85rem;
        height: 1.8rem;
        margin-bottom: 0.27rem;
        background-size: 100% 100%;
      }
      .yellow_pic{
        background-image: url("/static/images/createCases/web_doc_no_proposal.png");
      }
      .blue_pic{
        background-image: url("../../../../../common/imgs/3d/blue_web_doc_no_proposal.png");
      }
      .purple_pic{
        background-image: url("../../../../../common/imgs/3d/purple_web_doc_no_proposal.png");
      }
    }
  }
</style>
